// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300,400,500,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#share-ticket-v2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  gap: 25px;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  background-color: #f5f5f5;
  overflow: hidden;
}

#share-ticket-v2 .clients-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  gap: 5% 2.5%;

  justify-content: center;
  align-items: center;
}

#share-ticket-v2 .middle-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  gap: 15px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/routes/ShareTicketv2/ShareTicketv2.css"],"names":[],"mappings":"AAGA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,SAAS;EACT,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,qCAAqC;EACrC,YAAY;;EAEZ,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,+CAA+C;EAC/C,iBAAiB;EACjB,SAAS;EACT,gBAAgB;AAClB","sourcesContent":["@import url('/src/App.css');\n@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300,400,500,600&display=swap');\n\n#share-ticket-v2 {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  min-height: 100%;\n  gap: 25px;\n  overflow: auto;\n  overflow-x: hidden;\n  position: relative;\n  background-color: #f5f5f5;\n  overflow: hidden;\n}\n\n#share-ticket-v2 .clients-box {\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(3, 30%);\n  gap: 5% 2.5%;\n\n  justify-content: center;\n  align-items: center;\n}\n\n#share-ticket-v2 .middle-section {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  padding: 10px;\n  border-radius: 10px;\n  background-color: #f5f5f5;\n  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);\n  min-height: 100vh;\n  gap: 15px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
