import { useState, useEffect } from 'react'
import ProgressBar from 'react-customizable-progressbar'
import { FormattedMessage } from 'react-intl'

import './WaitProgressBar.css'


const WaitProgressBar = () => {
  // slow process: total: 60000ms, interval: 5000ms
  // fast process: total: 10000ms, interval: 1000ms
  const intervalTime = 2000 // 2 seconds
  const totalTime = 20000 // 60 seconds
  const [progress, setProgress] = useState(1.2)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        // Calculate the next progress using a logarithmic scale
        const newProgress = prevProgress + (Math.log(prevProgress + 0.01) / Math.log(totalTime)) * 100
        if(newProgress >= 98) {
          clearInterval(interval)
          return 95
        }

        return newProgress
      })
    }, intervalTime) // Adjust the interval according to your preference

    return () => clearInterval(interval) // Cleanup on unmount
  }, [totalTime])

  return (
    <ProgressBar
      children={<TextInside progress={progress} />}
      progress={progress}
      radius={100}
      steps={100}
      initialAnimation={true}
      trackStrokeWidth={12}
      strokeWidth={12}
      strokeColor={'#001b2f'}
      className="progress-bar"
    />
  )
}

export default WaitProgressBar

const TextInside = ({ progress }: { progress: number }) => {
  let text = "uploading-ticket-image"
  if( progress >= 2 && progress < 35) text =  "processing-ticket"
  else if( progress >= 35 && progress < 70) text = "detecting-products"
  else if( progress >= 70 && progress < 100) text = "finishing"
 
  return (
    <div id="text-inside">
      <strong><FormattedMessage id={text} /></strong>
      <strong>...</strong>
    </div>
  )
}
