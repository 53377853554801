
export interface TicketItems {
  confidence: number
  product_description: string
  product_id: number
  quantity: number
  session_id: string
  total_amount: number
  unit_price: number
  uniqueId?: string
  repeteadTimes?: number
}
export type ProductsForClients = Array<{ name: string; products: TicketItems[] }>

export enum Clients {
  MASTER = "master"
}