// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.ticket-check .scrollable-section {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 100%;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--light-blue) var(--dark-blue);
  height: 375px;
}

/* For Webkit browsers (Chrome, Safari) */
.ticket-check .scrollable-section ::-webkit-scrollbar {
  width: 8px;
  background-color: var(--dark-blue);
}

.ticket-check .scrollable-section ::-webkit-scrollbar-thumb {
  background-color: var(--light-blue);
}

.ticket-check .scrollable-section ::-webkit-scrollbar-track {
  background-color: transparent;
}

.confirm-btn {
  width: 100%;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--dark-blue);
  color: var(--white-blue);
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: var(--dark-blue);
    color: var(--white-blue);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TicketVerificationModal/TicketVerificationModal.css"],"names":[],"mappings":"AAGA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,sBAAsB;EACtB,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,mDAAmD;EACnD,aAAa;AACf;;AAEA,yCAAyC;AACzC;EACE,UAAU;EACV,kCAAkC;AACpC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;EAClC,wBAAwB;EACxB,kCAAkC;EAClC,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,oBAAoB;EACpB;IACE,kCAAkC;IAClC,wBAAwB;EAC1B;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');\n@import url('/src/App.css');\n\n.ticket-check {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  gap: 10px;\n  width: 100%;\n}\n\n.ticket-check .scrollable-section {\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  min-width: 100%;\n  overflow: auto;\n  overflow-x: hidden;\n  scrollbar-width: thin;\n  scrollbar-color: var(--light-blue) var(--dark-blue);\n  height: 375px;\n}\n\n/* For Webkit browsers (Chrome, Safari) */\n.ticket-check .scrollable-section ::-webkit-scrollbar {\n  width: 8px;\n  background-color: var(--dark-blue);\n}\n\n.ticket-check .scrollable-section ::-webkit-scrollbar-thumb {\n  background-color: var(--light-blue);\n}\n\n.ticket-check .scrollable-section ::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\n.confirm-btn {\n  width: 100%;\n  padding: 5px 20px;\n  border: none;\n  border-radius: 5px;\n  background-color: var(--dark-blue);\n  color: var(--white-blue);\n  font-family: 'Poppins', sans-serif;\n  font-weight: 600;\n  font-size: 1rem;\n  cursor: pointer;\n  transition: all 0.3s;\n  &:hover {\n    background-color: var(--dark-blue);\n    color: var(--white-blue);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
