import { useSelector } from 'react-redux'
import FloatingBarButtons from '../FloatingBarButtons/FloatingBarButtons'
import ClientsSummary from '../ClientsSumary/ClientsSumary'
import { TicketItems } from '../../types'

import './FloatingBar.css'


interface FloatingBarProps {
  currentClient: string
  setCurrentClient: (s: string) => void
  productsForClients: Map<string, TicketItems[]>
  setShowSummary: (s: boolean) => void
}
const FloatingBar = ({ currentClient, setCurrentClient, productsForClients, setShowSummary }: FloatingBarProps) => {
  const namesForClients: string[] = useSelector((s: any) => s.clientsName)
  return (
    <div className="floating-bar">
      <div className="content">
      <ClientsSummary
          key={namesForClients?.length}
          currentClient={currentClient}
          setCurrentClient={setCurrentClient}
          namesForClients={namesForClients}
          productsForClients={productsForClients}
        />
        <FloatingBarButtons setShowSummary={setShowSummary}  />
   
      </div>
    </div>
  )
}

export default FloatingBar
