// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-data-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.ticket-data-box .triple-grid {
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 14% 68% 20%;
  gap: 1%;
  place-items: start;
  strong {
    font-weight: 700;
    font-size: 0.9rem;
    color: var(--dark-blue);
  }
  input {
    width: 100%;
    padding: 2px;
    border: none;
    border-radius: 5px;
    color: var(--dark-blue);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
    background-color: var(--white-blue);
  }
}

.ticket-data-box .add-new-element {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  button {
    width: 50%;
    border: 2px solid var(--dark-blue);
    padding: 2px;
    background: transparent;
    color: var(--dark-blue);
    font-size: 13px;
    font-weight: 700;
    border-radius: 4px;
    &:hover {
      background-color: var(--dark-blue);
      color: var(--white-blue);
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TicketVerificationModal/TicketDataBox/TicketDataBox.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,kCAAkC;EAClC,OAAO;EACP,kBAAkB;EAClB;IACE,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;EACzB;EACA;IACE,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,kCAAkC;IAClC,gBAAgB;IAChB,iBAAiB;IACjB,mCAAmC;EACrC;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX;IACE,UAAU;IACV,kCAAkC;IAClC,YAAY;IACZ,uBAAuB;IACvB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB;MACE,kCAAkC;MAClC,wBAAwB;IAC1B;EACF;AACF","sourcesContent":[".ticket-data-box {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  gap: 10px;\n}\n.ticket-data-box .triple-grid {\n  max-width: 100%;\n  width: 100%;\n  display: grid;\n  grid-template-columns: 14% 68% 20%;\n  gap: 1%;\n  place-items: start;\n  strong {\n    font-weight: 700;\n    font-size: 0.9rem;\n    color: var(--dark-blue);\n  }\n  input {\n    width: 100%;\n    padding: 2px;\n    border: none;\n    border-radius: 5px;\n    color: var(--dark-blue);\n    font-family: 'Poppins', sans-serif;\n    font-weight: 500;\n    font-size: 0.8rem;\n    background-color: var(--white-blue);\n  }\n}\n\n.ticket-data-box .add-new-element {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  button {\n    width: 50%;\n    border: 2px solid var(--dark-blue);\n    padding: 2px;\n    background: transparent;\n    color: var(--dark-blue);\n    font-size: 13px;\n    font-weight: 700;\n    border-radius: 4px;\n    &:hover {\n      background-color: var(--dark-blue);\n      color: var(--white-blue);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
