import React, { createContext, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import UploadTicket from './routes/UploadTicket/UploadTicket'
import MobileNavbar from './components/Navbar/MobileNavbar'
import ShareTicketv2 from './routes/ShareTicketv2/ShareTicketv2'
import LoadSession from './routes/LoadSession/LoadSession'
import { SocketProvider } from './websockets/SocketContext'
import { BoxOptions } from './components/Navbar/BoxOptions/BoxOptions'
import { IntlProvider } from 'react-intl'
import enMessages from './locales/en.json'
import espMessages from './locales/es.json'
import { useSelector } from 'react-redux'

import './App.css'

export const TicketContext = createContext<any>(undefined)

export type Language = 'en' | 'es'

function App() {
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState<boolean>(false)
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const language: Language = useSelector((s: any) => s.language)

  return (
    <div className="App">
      <IntlProvider locale={language === 'en' ? 'en' : 'es'} messages={language === 'en' ? enMessages : espMessages}>
        <BrowserRouter>
          <SocketProvider>
            <TicketContext.Provider value={{ isOpenVerificationModal, setIsOpenVerificationModal, showOptions, setShowOptions }}>
              <MobileNavbar />
              {showOptions && <BoxOptions />}
              <Routes>
                <Route path="/" element={<UploadTicket />} />
                <Route path="/share-ticket/:sessionid/:user" element={<ShareTicketv2 />} />
                <Route path="/load/:sessionid" element={<LoadSession />} />
                <Route path="*" element={<UploadTicket />} />
              </Routes>
            </TicketContext.Provider>
          </SocketProvider>
        </BrowserRouter>
      </IntlProvider>
    </div>
  )
}

export default App
