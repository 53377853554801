// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow: auto;
  overflow-x: hidden;
}

#modal .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 355px;
  background-color: var(--white-blue);
  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
}

#modal .content h3 {
  text-align: center;
  color: var(--dark-blue);
  font-family: Poppins;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}

#modal .confirm-btn {
  width: 100%;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--dark-blue);
  color: var(--white-blue);
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: var(--dark-blue);
    color: var(--white-blue);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/BaseModal/BaseModal.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;EACT,YAAY;EACZ,mCAAmC;EACnC,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;EAClC,wBAAwB;EACxB,kCAAkC;EAClC,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,oBAAoB;EACpB;IACE,kCAAkC;IAClC,wBAAwB;EAC1B;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');\n@import url('/src/App.css');\n#modal {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  padding-top: 20px;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  min-height: 100vh;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.8);\n  z-index: 1000;\n  overflow: auto;\n  overflow-x: hidden;\n}\n\n#modal .content {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 20px;\n  width: 355px;\n  background-color: var(--white-blue);\n  border-radius: 20px;\n  padding: 10px;\n  box-sizing: border-box;\n}\n\n#modal .content h3 {\n  text-align: center;\n  color: var(--dark-blue);\n  font-family: Poppins;\n  font-weight: 700;\n  font-size: 20px;\n  margin: 0;\n}\n\n#modal .confirm-btn {\n  width: 100%;\n  padding: 5px 20px;\n  border: none;\n  border-radius: 5px;\n  background-color: var(--dark-blue);\n  color: var(--white-blue);\n  font-family: 'Poppins', sans-serif;\n  font-weight: 600;\n  font-size: 1rem;\n  cursor: pointer;\n  transition: all 0.3s;\n  &:hover {\n    background-color: var(--dark-blue);\n    color: var(--white-blue);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
