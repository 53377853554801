import { useNavigate } from 'react-router-dom'
import { ChangeEvent, useEffect, useState } from 'react'
import { addTicket, removeTicket } from '../../store/Ticket'
import { useDispatch, useSelector } from 'react-redux'
import BaseModal from '../BaseModal/BaseModal'
import TicketDataBox from './TicketDataBox/TicketDataBox'
import { Clients, TicketItems } from '../../types'
import { FormattedMessage } from 'react-intl'

import './TicketVerificationModal.css'


interface TicketVerificationModalProps {
  setIsOpen: (isOpen: boolean) => void
}

const TicketVerificationContentModal = ({ setIsOpen }: TicketVerificationModalProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isValidTicket, setIsValidTicket] = useState<boolean>()
  const [newProducts, setNewProducts] = useState<Map<number, TicketItems>>(new Map<number, TicketItems>())
  const ticket: TicketItems[] = useSelector((state: any) => state.ticket)
  const sessionId: string = useSelector((state: any) => state.sessionId)

  const handleConfirm = () => {
    navigate('/share-ticket/' + sessionId + '/' + Clients.MASTER)
    dispatch(removeTicket({}))
    const tempItems: TicketItems[] = Array.from(newProducts.values())
    const filteredIncompleteItems = tempItems.filter((item: TicketItems) => item.product_description.length > 0 && item.unit_price > 0)
    const ticketModified: TicketItems[] = [...ticket, ...filteredIncompleteItems]
    dispatch(addTicket(ticketModified))
    setIsOpen(false)
  }

  const handleDescriptionChange = (e: ChangeEvent<any>, uniqueId: string) => {
    const value = e.target.value
    const newTicket = ticket.map((item: TicketItems) => {
      if (item.uniqueId === uniqueId) {
        return { ...item, product_description: value }
      }
      return item
    })
    dispatch(addTicket(newTicket))
  }

  const handlePriceChange = (e: ChangeEvent<any>, uniqueId: string) => {
    const unitPrice = e.target.value
    const newTicket = ticket.map((item: TicketItems) => {
      if (item.uniqueId === uniqueId) {
        return { ...item, unit_price: !Number.isNaN(+unitPrice) ? +unitPrice : 0 }
      }
      return item
    })
    dispatch(addTicket(newTicket))
  }

  const handleRepeteadProduct = (e: ChangeEvent<any>, uniqueId: string) => {
    const value = e.target.value
    const newTicket = ticket.map((item: TicketItems) => {
      if (item.uniqueId === uniqueId) {
        return { ...item, repeteadTimes: value }
      }
      return item
    })
    dispatch(addTicket(newTicket))
  }
  // Verify that all products have description and price is higher > 0
  useEffect(() => {
    const isValid: boolean = ticket.every((item: TicketItems) => item.product_description.length > 0 && item.unit_price > 0)
    setIsValidTicket(isValid)
  }, [ticket])

  return (
    <div className="ticket-check">
      <section className="scrollable-section">
        <TicketDataBox
          ticket={ticket}
          handleDescriptionChange={handleDescriptionChange}
          handlePriceChange={handlePriceChange}
          handleRepeteadProduct={handleRepeteadProduct}
          setNewProducts={setNewProducts}
        />
      </section>
      <button onClick={handleConfirm} disabled={!isValidTicket} className="confirm-btn">
        <FormattedMessage id="confirm" />
      </button>
    </div>
  )
}

const TicketVerificationModal = ({ setIsOpen }: TicketVerificationModalProps) => {
  return (
    <BaseModal headerText="ticket-summary">
      <TicketVerificationContentModal setIsOpen={setIsOpen} />
    </BaseModal>
  )
}
export default TicketVerificationModal
