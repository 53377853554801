import { useMemo } from "react"
import { useSelector } from "react-redux"
import { ProductsForClients, TicketItems } from "../types"

const useProductsForClientMap = (): Map<string, TicketItems[]> => {
  const arrayProductsForClients: ProductsForClients = useSelector((s: any) => s.productsForClients)
  const productsForClients: Map<string, TicketItems[]> = useMemo(() => {
    const productsForClientsMap = new Map<string, TicketItems[]>()
    arrayProductsForClients.forEach((client) => {
      productsForClientsMap.set(client.name, client.products)
    })
    return productsForClientsMap
  }, [arrayProductsForClients])

  return productsForClients
}

export default useProductsForClientMap