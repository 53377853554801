import { ChangeEvent } from 'react'
import { TicketItems } from '../../../types'
import { useState } from 'react'
import { groupTicketItems } from '../../../helpers/ticketHelper'
import AddNewItemRow from './AddNewItemRow'
import { FormattedMessage } from 'react-intl'

import './TicketDataBox.css'


interface TicketDataBoxProps {
  ticket: TicketItems[]
  handleDescriptionChange: (e: ChangeEvent<any>, uniqueId: string) => void
  handlePriceChange: (e: ChangeEvent<any>, uniqueId: string) => void
  handleRepeteadProduct: (e: ChangeEvent<any>, uniqueId: string) => void
  setNewProducts: any
}

const TicketDataBox = ({ ticket, handleDescriptionChange, handlePriceChange, setNewProducts, handleRepeteadProduct }: TicketDataBoxProps) => {
  const groupedTicket: TicketItems[] = groupTicketItems(ticket)
  const [itemsToAdd, setItemToAdd] = useState<number>(0)
  const arrayForInputs: Array<number> = Array.from({ length: itemsToAdd })
  return (
    <section className="ticket-data-box">
      <div className="triple-grid">
        <strong>Ud.</strong>
        <strong><FormattedMessage id="product" /></strong>
        <strong>Ud. / €</strong>
      </div>

      {groupedTicket.map((item: TicketItems) => (
        <div className="triple-grid" key={item.uniqueId}>
          <input type="number" value={item.repeteadTimes} onChange={(e) => handleRepeteadProduct(e, item?.uniqueId as string)} placeholder="Unidades" />
          <input
            type="text"
            value={item.product_description}
            onChange={(e) => handleDescriptionChange(e, item?.uniqueId as string)}
            placeholder="Descripción"
          />
          <input type="number" value={item.unit_price} onChange={(e) => handlePriceChange(e, item?.uniqueId as string)} placeholder="Precio" />
        </div>
      ))}
      {itemsToAdd > 0 && (
        <>
          {arrayForInputs.map((_, i: number) => (
            <AddNewItemRow key={i} setNewProducts={setNewProducts} newRowIndex={i} />
          ))}
        </>
      )}
      <div className="add-new-element">
        <button className="add-btn" onClick={() => setItemToAdd((s: number) => s + 1)}>
        <FormattedMessage id="add-element" />
        </button>
      </div>
    </section>
  )
}

export default TicketDataBox
