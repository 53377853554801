import { useState, useEffect, ChangeEvent } from 'react'
import { TicketItems } from '../../../types'

interface AddNewItemRowProps {
  setNewProducts: (s: any) => void
  newRowIndex: number
}

const AddNewItemRow = ({ setNewProducts, newRowIndex }: AddNewItemRowProps) => {
  const [description, setDescription] = useState<string>('')
  const [price, setPrice] = useState<string>('0')
  const [quantity, setQuantity] = useState<number>(1)

  useEffect(() => {
    setNewProducts((s: any) => {
      const newProducts: Map<number, TicketItems> = new Map(s)
      if (description === '' || price === '0' || isNaN(parseFloat(price))) return newProducts
      const newItem: TicketItems = {
        product_description: description.toUpperCase(),
        unit_price: parseFloat(price),
        uniqueId: `${description}-${newRowIndex}`,
        confidence: 1,
        quantity: 1,
        product_id: newRowIndex,
        session_id: '', // TODO: add real session_id
        total_amount: parseFloat(price),
      }
      newProducts.set(newRowIndex, newItem)
      return newProducts
    })
  }, [description, price, newRowIndex, setNewProducts])
  return (
    <div className="triple-grid">
      <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value as unknown as number)} placeholder="Unidades" />
      <input type="text" placeholder="Producto" onChange={(e: ChangeEvent<any>) => setDescription(e.target.value)} />
      <input type="text" placeholder="10.20" onChange={(e: ChangeEvent<any>) => setPrice(e.target.value)}/>
    </div>
  )
}

export default AddNewItemRow
