import React, { ChangeEvent, useContext, useState } from 'react'
import { UPLOAD_API_URL } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { addSessionId } from '../../store/Session'
import { addTicket } from '../../store/Ticket'
import TicketVerificationModal from '../../components/TicketVerificationModal/TicketVerificationModal'
import { Language, TicketContext } from '../../App'
import WaitProgressBar from '../../components/WaitProgressBar/WaitProgressBar'
import useRemoveTicketAndSessionId from '../../hooks/useRemoveTicket'
import { addSubFixForDuplicated } from '../../helpers/getCanonicalName'
import { TicketItems } from '../../types'
import { FormattedMessage } from 'react-intl'
import { addClientsName } from '../../store/ClientsName'
import { updateProducts } from '../../store/ProductsForClients'

import './UploadTicket.css'

const UploadTicket = () => {
  const [error, setError] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { isOpenVerificationModal, setIsOpenVerificationModal } = useContext(TicketContext) as any
  const language: Language = useSelector((s: any) => s.language)
  const dispatch = useDispatch()

  useRemoveTicketAndSessionId()

  const handleSentImage = async (e: ChangeEvent<HTMLInputElement>) => {
    let file: File
    setError('')
    if (e.target.files) {
      file = e.target.files[0]
      setSelectedFile(e.target.files[0])
    } else return

    dispatch(addTicket([]))
    dispatch(addSessionId(''))
    dispatch(addClientsName([]))
    dispatch(updateProducts([]))
    window.localStorage.clear()
    setError('')
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('image', file as any)
      formData.append('language', language as any)

      const response = await fetch(UPLOAD_API_URL, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        const data: { ticket: TicketItems[]; session_id: string } = await response.json()
        console.log('File uploaded successfully. Ticket response:', data?.ticket)
        const productNames: string[] = data.ticket.map((t: any) => t.product_description)
        const productArray: string[] = addSubFixForDuplicated(productNames)
        const modifiedTicket: TicketItems[] = data.ticket.map((item: TicketItems, i: number) => ({ ...item, uniqueId: productArray[i] }))
        dispatch(addTicket(modifiedTicket))
        const sessionId = data.session_id ?? '1234_id'
        dispatch(addSessionId(sessionId))
        console.log('For SessionID:', data.session_id, ' Ticket added to store:', modifiedTicket)
        setIsOpenVerificationModal(true)
      } else if (response.status === 500) {
        setError('No conseguimos reconocer el contenido del ticket, sube otra foto por favor!')
        console.error('No conseguimos reconocer el contenido del ticket, sube otra foto por favor!')
      } else {
        setError('Failed to upload file')
        console.error('Failed to upload file', response.status)
      }
    } catch (error: any) {
      console.error('Error uploading file:', error.message)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <main className="upload-ticket-container">
      {isOpenVerificationModal && <TicketVerificationModal setIsOpen={setIsOpenVerificationModal} />}
      <div className="title-ticket-container">
        <h1>BILITO</h1>
        <h4>
          <FormattedMessage id="eating-with-friends" />
          <br />
          <FormattedMessage id="share-your-bill" />
        </h4>
      </div>
      <div className="input-ticket-container">
        <button className="select-ticket-button"> {selectedFile ? <FormattedMessage id="selected-ticked" /> : <FormattedMessage id="scan-ticket" />}</button>
        <input type="file" accept="image/*" className="input-file" onChange={handleSentImage} />
      </div>
      <p>
        <FormattedMessage id="put-receipt-over-table" />
        <br /> <FormattedMessage id="it-works-better" />
      </p>
      {isLoading && <WaitProgressBar />}
      {error && (
        <span className="error-message">
          <FormattedMessage id="failed-to-upload-ticket" />
        </span>
      )}
    </main>
  )
}

export default UploadTicket
