// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floating-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  background-color: var(--dark-blue);
  z-index: 1000;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    padding: 4px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/FloatingBar/FloatingBar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,gBAAgB;EAChB,kCAAkC;EAClC,aAAa;EACb;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,SAAS;IACT,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;EACrB;AACF","sourcesContent":[".floating-bar {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  max-height: 100%;\n  background-color: var(--dark-blue);\n  z-index: 1000;\n  .content {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n    gap: 10px;\n    padding: 4px;\n    padding-top: 10px;\n    padding-bottom: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
