import { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { WEBSOCKET_URL } from '../config'
import { ProductsForClients } from '../types'

export const SocketContext = createContext(null) as any

export const SocketProvider = ({ children }: { children: any }) => {
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [error, setError] = useState<any>(undefined)
  const [receivedData, setReceivedData] = useState<any>(undefined)
  const location = useLocation()
  const pathname: string[] = location?.pathname.split('/')
  const sessionId = pathname[2]
  const user = pathname[3]

  console.table({ webSocketSession: sessionId, websocketUser: user })

  const cleanReceivedData = () => {
    setReceivedData(undefined)
  }

  const connect = (userId: string, sessionId: string) => {
    try {
      setSocket((prevSocket: WebSocket | null) => {
        if (!prevSocket) return new WebSocket(`${WEBSOCKET_URL}${sessionId}?user_id=${userId}`)
        return prevSocket
      })
    } catch (error) {
      console.error('Error connecting to websocket', error)
    }
  }

  const sendUpdateProductsForClients = (productsForClients: ProductsForClients) => {
    const message = JSON.stringify({ products: productsForClients, state: 'update' })
    socket?.send(message)
  }

  //* Open connection
  useEffect(() => {
    socket?.addEventListener('open', (event: Event) => {
      console.log(`Socket connected with user ${user} in session ${sessionId}`)
    })

    return () => {
      socket?.removeEventListener('open', () => console.log('unsubscribed'))
    }
  }, [socket, sessionId, user])

  //* Error handler
  useEffect(() => {
    socket?.addEventListener('error', (event: Event) => {
      console.error('Error in socket with user', event)
      setError(event)
    })

    return () => {
      socket?.removeEventListener('error', () => console.log('unsubscribed'))
    }
  }, [socket])

  //* listen messages
  useEffect(() => {
    socket?.addEventListener('message', (event) => {
      const messageData = event?.data
      if (!messageData) return
      setReceivedData(messageData)
    })
    return () => {
      socket?.removeEventListener('message', () => console.log('unsubscribed'))
    }
  }, [socket])

  //* Close connection
  useEffect(() => {
    const handleSocketClose = (event: any) => {
      console.warn(`Socket  for session ${sessionId} and user ${user}closed with code ${event?.code} and reason ${event?.reason}`)
      // No deberiamos usar el metodo connect?
      setSocket(null)
      console.warn('Reconnecting socket.', user, sessionId)
      setTimeout(() => connect(user, sessionId), 2000)
    }

    socket?.addEventListener('close', handleSocketClose)

    return () => {
      socket?.removeEventListener('close', handleSocketClose)
    }
  }, [socket, sessionId, user])

  //* Reconnect mechanism
  useEffect(() => {
    if (socket?.readyState === WebSocket.CLOSED) {
      console.warn('Reconnecting socket.', user, sessionId)
      const newSocket = new WebSocket(`${WEBSOCKET_URL}${sessionId}?user_id=${user}`)
      setSocket(newSocket)
    }
  }, [socket, sessionId, user])

  return (
    <SocketContext.Provider value={{ connect, sendUpdateProductsForClients, cleanReceivedData, socket, receivedData, error }}>
      {children}
    </SocketContext.Provider>
  )
}
