import { imgs } from '../../images'
import { TicketItems } from '../../types'
import { useContext } from 'react'
import { TicketContext } from '../../App'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import './MobileNavbar.css'

const MobileNavbar = () => {
  const ticket: TicketItems[] = useSelector((state: any) => state.ticket)
  const totalAmount: number = (ticket ? ticket?.reduce((acc, item) => acc + item.unit_price, 0) : undefined) as number
  const { showOptions, setShowOptions } = useContext(TicketContext) as any
  const isTicket: boolean = ticket?.length > 0

  return (
    <nav>
      <div className="mobile-navbar">
        <Link to="/" className="link">
          <img src={imgs.logo} alt="bilito" className="logo" />
        </Link>
        <div className="right">
          {totalAmount !== undefined && totalAmount > 0 && <span>Total: {totalAmount?.toFixed(2)} €</span>}
          {isTicket && <img src={imgs.options} alt="Options" className="menu" onClick={() => setShowOptions(!showOptions)} />}
        </div>
      </div>
    </nav>
  )
}

export default MobileNavbar
