import logo from "./bilito-icon.png"
import info from "./info.svg"
import downArrow from "./downArrow.svg"
import rightArrow from "./rightArrow.svg"
import clipboard from "./clipboard.svg"
import options from "./options.svg"
import share from "./share.svg"
import shareLightBlue from "./shareLightBlue.svg"
import spain from "./spain.svg"
import eeuu from "./eeuu.svg"

export const imgs = {
  logo,
  info,
  downArrow,
  rightArrow,
  clipboard,
  options,
  share,
  shareLightBlue,
  spain,
  eeuu,
}