import { useEffect, useMemo, useState } from 'react'
import ClientsNumberModal from '../../components/ClientsNumberModal/ClientsNumberModal'
import ProductList from '../../components/ProductList/ProductList'
import SummaryTicketClients from '../../components/SummaryTicketClients/SummaryTicketClients'
import TicketVerificationModal from '../../components/TicketVerificationModal/TicketVerificationModal'
import { useContext } from 'react'
import { TicketContext } from '../../App'
import { useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { Clients, ProductsForClients, TicketItems } from '../../types'
import FloatingBar from '../../components/FloatingBar/FloatingBar'

import './ShareTicketv2.css'
import '../../components/ProductList/ProductList.css'

const ShareTicketv2 = () => {
  const { user } = useParams<{ user: string }>()
  const [showClientsModal, setShowClientsModal] = useState<boolean>(true)
  const [currentClient, setCurrentClient] = useState<string>('')
  const [showSummary, setShowSummary] = useState<Boolean>(false)
  const { isOpenVerificationModal, setIsOpenVerificationModal } = useContext(TicketContext) as any
  const namesForClients: string[] = useSelector((s: any) => s.clientsName)
  const arrayProductsForClients: ProductsForClients = useSelector((s: any) => s.productsForClients)

  const productsForClients: Map<string, TicketItems[]> = useMemo(() => {
    const productsForClientsMap = new Map<string, TicketItems[]>()
    arrayProductsForClients.forEach((client) => {
      productsForClientsMap.set(client.name, client.products)
    })
    return productsForClientsMap
  }, [arrayProductsForClients])

  useEffect(() => {
    if (namesForClients.length > 0) {
      setCurrentClient(namesForClients[0])
    }
  }, [namesForClients])

  return (
    <section id="share-ticket-v2">
      <FloatingBar currentClient={currentClient} setCurrentClient={setCurrentClient} productsForClients={productsForClients} setShowSummary={setShowSummary} />
      {isOpenVerificationModal && <TicketVerificationModal setIsOpen={setIsOpenVerificationModal as (isOpen: boolean) => void} />}
      {showSummary && <SummaryTicketClients setShowSummary={setShowSummary} />}
      {showClientsModal && user === Clients.MASTER && <ClientsNumberModal setShowClientsModal={setShowClientsModal} />}
      <section className="middle-section">
        <ProductList key={currentClient} currentClient={currentClient} productsForClients={productsForClients} />
      </section>
    </section>
  )
}
// Check you can access to the route
const ShareTicketv2Wrapper = () => {
  const { sessionid: routeSessionId } = useParams<{ sessionid: string }>()
  const mySessionId = useSelector((state: any) => state.sessionId)
  if (routeSessionId?.toLowerCase() !== mySessionId.toLowerCase()) return <Navigate to="/" />
  return <ShareTicketv2 />
}

export default ShareTicketv2Wrapper
