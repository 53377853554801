// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
}

.flex-c-lc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.flex-c-cc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.flex-c-cr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
  width: 100%;
}

.pa-5 {
  padding: 5px;
}

/* * {
  -webkit-tap-highlight-color: transparent !important;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
}
:root {
  overflow: auto;
  overflow-x: hidden;
  font-family: Poppins;
  --dark-blue: #001b2f;
  --blue: #00a9ff;
  --light-blue: #89cff3;
  --very-light-blue: #a0e9ff;
  --white-blue: #cdf5fd;
  --font: Poppins;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;;GAEG;;AAEH;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,oBAAoB;AACtB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,oBAAoB;EACpB,oBAAoB;EACpB,eAAe;EACf,qBAAqB;EACrB,0BAA0B;EAC1B,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');\n.App {\n  min-height: 100vh;\n  width: 100%;\n  overflow: auto;\n  overflow-x: hidden;\n  position: relative;\n}\n\n.flex-c-lc {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 5px;\n}\n\n.flex-c-cc {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 5px;\n  width: 100%;\n}\n\n.flex-c-cr {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n  gap: 5px;\n  width: 100%;\n}\n\n.pa-5 {\n  padding: 5px;\n}\n\n/* * {\n  -webkit-tap-highlight-color: transparent !important;\n} */\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: Poppins;\n}\n:root {\n  overflow: auto;\n  overflow-x: hidden;\n  font-family: Poppins;\n  --dark-blue: #001b2f;\n  --blue: #00a9ff;\n  --light-blue: #89cff3;\n  --very-light-blue: #a0e9ff;\n  --white-blue: #cdf5fd;\n  --font: Poppins;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
