// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-options {
  position: fixed;
  z-index: 999;
  width: 100%;
  min-height: 100vh;
  background-color: var(--white-blue);
  opacity: 0.9;
  animation: moveFromRight 0.5s ease-in-out;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    button {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100% !important;
      padding: 12px !important;
      border: none;
      background-color: transparent;
      color: var(--dark-blue);
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      text-align: left;
      border-bottom: 1px solid var(--dark-blue) !important;
    }
    span {
      color: var(--dark-blue);
      font-size: 18px;
      font-weight: bold;
    }
    img {
      width: 32px;
    }
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/BoxOptions/BoxOptions.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,mCAAmC;EACnC,YAAY;EACZ,yCAAyC;EACzC;IACE,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;IACvB,SAAS;IACT,aAAa;IACb;MACE,aAAa;MACb,mBAAmB;MACnB,SAAS;MACT,sBAAsB;MACtB,wBAAwB;MACxB,YAAY;MACZ,6BAA6B;MAC7B,uBAAuB;MACvB,eAAe;MACf,iBAAiB;MACjB,eAAe;MACf,gBAAgB;MAChB,oDAAoD;IACtD;IACA;MACE,uBAAuB;MACvB,eAAe;MACf,iBAAiB;IACnB;IACA;MACE,WAAW;IACb;EACF;AACF;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".box-options {\n  position: fixed;\n  z-index: 999;\n  width: 100%;\n  min-height: 100vh;\n  background-color: var(--white-blue);\n  opacity: 0.9;\n  animation: moveFromRight 0.5s ease-in-out;\n  .content {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    gap: 10px;\n    padding: 10px;\n    button {\n      display: flex;\n      align-items: center;\n      gap: 10px;\n      width: 100% !important;\n      padding: 12px !important;\n      border: none;\n      background-color: transparent;\n      color: var(--dark-blue);\n      font-size: 18px;\n      font-weight: bold;\n      cursor: pointer;\n      text-align: left;\n      border-bottom: 1px solid var(--dark-blue) !important;\n    }\n    span {\n      color: var(--dark-blue);\n      font-size: 18px;\n      font-weight: bold;\n    }\n    img {\n      width: 32px;\n    }\n  }\n}\n\n@keyframes moveFromRight {\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
