import { useContext, useEffect, useState } from 'react'
import ProductItemList from './ProductItemList'
import { useDispatch, useSelector } from 'react-redux'
import { SocketContext } from '../../websockets/SocketContext'
import SelectAllRow from './SelectAllRow'
import { updateProducts } from '../../store/ProductsForClients'
import { TicketItems } from '../../types'
import { FormattedMessage } from 'react-intl'

import './ProductList.css'

interface ProductListProps {
  currentClient: string
  productsForClients: Map<string, TicketItems[]>
}

const ProductList = ({ currentClient, productsForClients }: ProductListProps) => {
  const [selectedItems, setSelectedItems] = useState<TicketItems[]>(productsForClients?.get(currentClient) || [])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const ticket: TicketItems[] = useSelector((state: any) => state.ticket)
  const { receivedData, socket, cleanReceivedData } = useContext(SocketContext) as any
  const dispatch = useDispatch()

  //* useEffect to handle received data
  useEffect(() => {
    if (receivedData) {
      let receivedDataParsed
      try {
        receivedDataParsed = JSON.parse(receivedData)
      } catch (error) {
        console.error('Error parsing received data', error)
        return
      }
      if (receivedDataParsed?.state !== 'update') return
      const receivedProductForClients: Array<{ name: string; products: TicketItems[] }> = receivedDataParsed?.products
      if (receivedProductForClients && receivedProductForClients?.length > 0) {
        dispatch(updateProducts(receivedProductForClients))
        const productsForThisClient = receivedProductForClients.find((client) => client.name.toLowerCase() === currentClient.toLowerCase())
        setSelectedItems(productsForThisClient?.products || [])
      }
      cleanReceivedData()
    }
  }, [receivedData, dispatch, socket, socket?.readyState, currentClient, cleanReceivedData])

  return (
    <div className="container-product-list">
      <h3>
        <FormattedMessage id="what-take-client" values={{ currentClient }} />
      </h3>
      <ul className="ticket-list">
        <SelectAllRow selectAll={selectAll} setSelectAll={setSelectAll} setSelectedItems={setSelectedItems} currentClient={currentClient} />
        {ticket.map((item: TicketItems) => (
          <ProductItemList key={item.uniqueId} item={item} setSelectedItems={setSelectedItems} selectedItems={selectedItems} currentClient={currentClient} />
        ))}
      </ul>
    </div>
  )
}

export default ProductList
