import { useContext } from 'react'
import { Language, TicketContext } from '../../../App'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage } from '../../../store/languageSlice'

import './BoxOptions.css'
import { imgs } from '../../../images'


export const BoxOptions = () => {
  const { setIsOpenVerificationModal, setShowOptions } = useContext(TicketContext) as any
  const navigate = useNavigate()
  const language: Language = useSelector((s: any) => s.language)
  const imgLanguage = language === 'en' ? imgs.eeuu : imgs.spain
  const dispatch = useDispatch()
  const changeLanguageHandler = () => {
    dispatch(changeLanguage(language === 'en' ? 'es' : 'en'))
  }

  const handleEdit = () => {
    setIsOpenVerificationModal(true)
    setShowOptions(false)
  }

  const handleNavigate = () => {
    navigate('/')
    window.localStorage.clear()
  }

  return (
    <div className="box-options">
      <div className="content">
        <button onClick={() => handleEdit()}>
          <FormattedMessage id="edit-ticket" />
        </button>
        <button onClick={() => handleNavigate()}>
          <FormattedMessage id="use-other-ticket" />
        </button>
        <button onClick={() => changeLanguageHandler()}>
          <FormattedMessage id="change-language" />: <img src={imgLanguage} alt="language" />
        </button>
      </div>
    </div>
  )
}
