import { useDispatch, useSelector } from 'react-redux'
import { updateProducts } from '../../store/ProductsForClients'
import { ProductsForClients, TicketItems } from '../../types'
import { useContext } from 'react'
import { SocketContext } from '../../websockets/SocketContext'
import { FormattedMessage } from 'react-intl'

interface SelectAllRowProps {
  setSelectedItems: (s: any) => void
  setSelectAll: (s: boolean) => void
  selectAll: boolean
  currentClient: string
}

const SelectAllRow = ({ setSelectedItems, setSelectAll, selectAll, currentClient }: SelectAllRowProps) => {
  const { sendUpdateProductsForClients } = useContext(SocketContext) as any
  const ticket: TicketItems[] = useSelector((state: any) => state.ticket)
  const productsForClients: ProductsForClients = useSelector((s: any) => s.productsForClients)
  const dispatch = useDispatch()

  const handleSelectAll = () => {
    const selected: TicketItems[] = selectAll ? [] : ticket
    setSelectedItems(selected)
    const newArray: ProductsForClients = productsForClients.map((clientInfo) => (clientInfo.name === currentClient ? { name: clientInfo.name, products: selected } : clientInfo))
    sendUpdateProductsForClients(newArray)
    dispatch(updateProducts(newArray))
    setSelectAll(!selectAll)
  }
  return (
    <li>
      <div>
        <input type="checkbox" className="checkbox" checked={selectAll === true} onChange={handleSelectAll} />
        <strong><FormattedMessage id="select-all" /></strong>
      </div>
    </li>
  )
}

export default SelectAllRow
