import { PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'
import './BaseModal.css'


interface BaseModalProps extends PropsWithChildren {
  headerText: string
}

const BaseModal: React.FC<BaseModalProps> = ({ children, headerText }) => {
  return (
    <div id="modal">
      <div className="content">
        <h3><FormattedMessage id={headerText} /></h3>
        {children}
      </div>
    </div>
  )
}

export default BaseModal
